import React from "react"
import cx from "classnames"

import Layout from "../components/layout"
import * as styles from "../assets/styles/servant.module.scss"
import FeedbackSection from "../components/feedback-section"
import { IMG } from "../const"

const Servant = () => {
  return (
    <Layout
      pageTitle="Project Servant — web development, UI/UX design"
      defaultImage={`${IMG.THUMBNAILS}/thumbnail-mvp.jpg`}
    >
      {() => {
        return (
          <>
            <main className={styles.servantBlock}>
              <article>
                <div className={styles.servantBlock__headline}>
                  <h1>Servant</h1>
                  <p>
                    Servant is a social network that helps people who love work
                    with their hands to find a good job. The service will be
                    useful for employees, employers, and recruiters.
                  </p>
                  <picture>
                    <source
                      media="(max-width: 428px)"
                      srcSet={`
										${IMG.SERVANT}/siteview/382.avif,
										${IMG.SERVANT}/siteview/764.avif 2x,
										${IMG.SERVANT}/siteview/984.avif 3x`}
                      type="image/avif"
                    />
                    <source
                      media="(max-width: 428px)"
                      srcSet={`
										${IMG.SERVANT}/siteview/382.webp,
										${IMG.SERVANT}/siteview/764.webp 2x,
										${IMG.SERVANT}/siteview/984.webp 3x`}
                      type="image/webp"
                    />
                    <source
                      media="(max-width: 428px)"
                      srcSet={`
										${IMG.SERVANT}/siteview/382.png,
										${IMG.SERVANT}/siteview/764.png 2x,
										${IMG.SERVANT}/siteview/984.png 3x`}
                    />
                    <source
                      media="(min-width: 429px) and (max-width: 767px)"
                      srcSet={`
										${IMG.SERVANT}/siteview/764.avif,
										${IMG.SERVANT}/siteview/1528.avif 2x,
										${IMG.SERVANT}/siteview/1968.avif 3x`}
                      type="image/avif"
                    />
                    <source
                      media="(min-width: 429px) and (max-width: 767px)"
                      srcSet={`
										${IMG.SERVANT}/siteview/764.webp,
										${IMG.SERVANT}/siteview/1528.webp 2x,
										${IMG.SERVANT}/siteview/1968.webp 3x`}
                      type="image/webp"
                    />
                    <source
                      media="(min-width: 429px) and (max-width: 767px)"
                      srcSet={`
										${IMG.SERVANT}/siteview/764.png,
										${IMG.SERVANT}/siteview/1528.png 2x,
										${IMG.SERVANT}/siteview/1968.png 3x`}
                    />
                    <source
                      media="(min-width: 768px)"
                      srcSet={`
										${IMG.SERVANT}/siteview/984.avif,
										${IMG.SERVANT}/siteview/1968.avif 2x,
										${IMG.SERVANT}/siteview/2952.avif 3x`}
                      type="image/avif"
                    />
                    <source
                      media="(min-width: 768px)"
                      srcSet={`
										${IMG.SERVANT}/siteview/984.webp,
										${IMG.SERVANT}/siteview/1968.webp 2x,
										${IMG.SERVANT}/siteview/2952.webp 3x`}
                      type="image/webp"
                    />
                    <source
                      media="(min-width: 768px)"
                      srcSet={`
										${IMG.SERVANT}/siteview/984.png,
										${IMG.SERVANT}/siteview/1968.png 2x,
										${IMG.SERVANT}/siteview/2952.png 3x`}
                    />
                    <img
                      alt="Servant: site review"
                      src={`${IMG.SERVANT}/siteview/2556.png`}
                      width="288"
                      height="206"
                      // Don't use `loading="lazy"` for the first screen
                      // loading="lazy"
                    />
                  </picture>
                </div>
                <section
                  className={cx(
                    styles.servantBlock__service,
                    styles.section,
                    "common__article__section"
                  )}
                >
                  <div>
                    <h2>About the service</h2>
                    <p>
                      Servant makes interactions between employees and employers
                      higher quality and more productive. Primarily the platform
                      focuses on agriculture, construction, home services and
                      repair, production, and auto transportation employees. The
                      web application allows users to find current conditions
                      for a particular market landscape, create a profile, apply
                      for a job post and communicate with other network users.
                      The same feature is offered for employers — they can make
                      a job post, contact applicants and find an employee with
                      the required skills.
                    </p>
                  </div>
                  <div>
                    <picture>
                      <source
                        media="(max-width: 428px)"
                        srcSet={`
											${IMG.SERVANT}/employees/382.avif,
											${IMG.SERVANT}/employees/764.avif 2x,
											${IMG.SERVANT}/employees/984.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        media="(max-width: 428px)"
                        srcSet={`
											${IMG.SERVANT}/employees/382.webp,
											${IMG.SERVANT}/employees/764.webp 2x,
											${IMG.SERVANT}/employees/984.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        media="(max-width: 428px)"
                        srcSet={`
											${IMG.SERVANT}/employees/382.png,
											${IMG.SERVANT}/employees/764.png 2x,
											${IMG.SERVANT}/employees/984.png 3x`}
                      />
                      <source
                        media="(min-width: 429px) and (max-width: 767px)"
                        srcSet={`
											${IMG.SERVANT}/employees/594.avif,
											${IMG.SERVANT}/employees/1188.avif 2x,
											${IMG.SERVANT}/employees/1782.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        media="(min-width: 429px) and (max-width: 767px)"
                        srcSet={`
											${IMG.SERVANT}/employees/594.webp,
											${IMG.SERVANT}/employees/1188.webp 2x,
											${IMG.SERVANT}/employees/1782.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        media="(min-width: 429px) and (max-width: 767px)"
                        srcSet={`
											${IMG.SERVANT}/employees/594.png,
											${IMG.SERVANT}/employees/1188.png 2x,
											${IMG.SERVANT}/employees/1782.png 3x`}
                      />
                      <source
                        media="(min-width: 768px)"
                        srcSet={`
											${IMG.SERVANT}/employees/764.avif,
											${IMG.SERVANT}/employees/1782.avif 2x,
											${IMG.SERVANT}/employees/2292.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        media="(min-width: 768px)"
                        srcSet={`
											${IMG.SERVANT}/employees/764.webp,
											${IMG.SERVANT}/employees/1782.webp 2x,
											${IMG.SERVANT}/employees/2292.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        media="(min-width: 768px)"
                        srcSet={`
											${IMG.SERVANT}/employees/764.png,
											${IMG.SERVANT}/employees/1782.png 2x,
											${IMG.SERVANT}/employees/2292.png 3x`}
                      />
                      <img
                        alt="Servant: search for employees"
                        src={`${IMG.SERVANT}/employees/2292.png`}
                        width="288"
                        height="206"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                </section>
                <section
                  className={cx(
                    styles.servantBlock__company,
                    styles.section,
                    "common__article__section"
                  )}
                >
                  <div>
                    <h2>About the company</h2>
                    <p>
                      Servant is concerned about the employment problem because
                      that 6.5 million Ukrainians temporarily or permanently
                      work abroad. 40% of those do not have legal work permits
                      and are not protected. This is not only a Ukrainian
                      problem but a worldwide one. Servant intends to build a
                      platform where people can quickly find work worldwide, get
                      all necessary documents and permissions and work safely.
                      Servant will solve this problem by involving legal
                      advisors and insurance company representatives to make the
                      whole process of employment abroad legal, smooth and as
                      simple as possible.
                    </p>
                  </div>
                  <div>
                    <picture>
                      <source
                        media="(max-width: 428px)"
                        srcSet={`
											${IMG.SERVANT}/vacancy/382.avif,
											${IMG.SERVANT}/vacancy/764.avif 2x,
											${IMG.SERVANT}/vacancy/984.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        media="(max-width: 428px)"
                        srcSet={`
											${IMG.SERVANT}/vacancy/382.webp,
											${IMG.SERVANT}/vacancy/764.webp 2x,
											${IMG.SERVANT}/vacancy/984.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        media="(max-width: 428px)"
                        srcSet={`
											${IMG.SERVANT}/vacancy/382.png,
											${IMG.SERVANT}/vacancy/764.png 2x,
											${IMG.SERVANT}/vacancy/984.png 3x`}
                      />
                      <source
                        media="(min-width: 429px)"
                        srcSet={`
											${IMG.SERVANT}/vacancy/594.avif,
											${IMG.SERVANT}/vacancy/1188.avif 2x,
											${IMG.SERVANT}/vacancy/1782.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        media="(min-width: 429px)"
                        srcSet={`
											${IMG.SERVANT}/vacancy/594.webp,
											${IMG.SERVANT}/vacancy/1188.webp 2x,
											${IMG.SERVANT}/vacancy/1782.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        media="(min-width: 429px)"
                        srcSet={`
											${IMG.SERVANT}/vacancy/594.png,
											${IMG.SERVANT}/vacancy/1188.png 2x,
											${IMG.SERVANT}/vacancy/1782.png 3x`}
                      />
                      <img
                        alt="Servant: vacancy editing"
                        src={`${IMG.SERVANT}/vacancy/2556.png`}
                        width="288"
                        height="206"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                </section>
                <hr className={styles.servantBlock__intermission} />
                <section
                  className={cx(
                    styles.servantBlock__functionality,
                    "common__article__section"
                  )}
                >
                  <p>
                    Users who are looking for a job can use the job search page.
                    The implemented sorting and filtering functionality makes it
                    easy to find a job that fits your skills best. Users can
                    filter by country and category including agriculture,
                    construction, repair, home services, etc.
                  </p>
                  <picture>
                    <source
                      media="(max-width: 428px)"
                      srcSet={`
										${IMG.SERVANT}/jobs/382.avif,
										${IMG.SERVANT}/jobs/764.avif 2x,
										${IMG.SERVANT}/jobs/984.avif 3x`}
                      type="image/avif"
                    />
                    <source
                      media="(max-width: 428px)"
                      srcSet={`
										${IMG.SERVANT}/jobs/382.webp,
										${IMG.SERVANT}/jobs/764.webp 2x,
										${IMG.SERVANT}/jobs/984.webp 3x`}
                      type="image/webp"
                    />
                    <source
                      media="(max-width: 428px)"
                      srcSet={`
										${IMG.SERVANT}/jobs/382.png,
										${IMG.SERVANT}/jobs/764.png 2x,
										${IMG.SERVANT}/jobs/984.png 3x`}
                    />
                    <source
                      media="(min-width: 429px) and (max-width: 767px)"
                      srcSet={`
										${IMG.SERVANT}/jobs/594.avif,
										${IMG.SERVANT}/jobs/1188.avif 2x,
										${IMG.SERVANT}/jobs/1782.avif 3x`}
                      type="image/avif"
                    />
                    <source
                      media="(min-width: 429px) and (max-width: 767px)"
                      srcSet={`
										${IMG.SERVANT}/jobs/594.webp,
										${IMG.SERVANT}/jobs/1188.webp 2x,
										${IMG.SERVANT}/jobs/1782.webp 3x`}
                      type="image/webp"
                    />
                    <source
                      media="(min-width: 429px) and (max-width: 767px)"
                      srcSet={`
										${IMG.SERVANT}/jobs/594.png,
										${IMG.SERVANT}/jobs/1188.png 2x,
										${IMG.SERVANT}/jobs/1782.png 3x`}
                    />
                    <source
                      media="(min-width: 768px)"
                      srcSet={`
										${IMG.SERVANT}/jobs/764.avif,
										${IMG.SERVANT}/jobs/1528.avif 2x,
										${IMG.SERVANT}/jobs/2292.avif 3x`}
                      type="image/avif"
                    />
                    <source
                      media="(min-width: 768px)"
                      srcSet={`
										${IMG.SERVANT}/jobs/764.webp,
										${IMG.SERVANT}/jobs/1528.webp 2x,
										${IMG.SERVANT}/jobs/2292.webp 3x`}
                      type="image/webp"
                    />
                    <source
                      media="(min-width: 768px)"
                      srcSet={`
										${IMG.SERVANT}/jobs/764.png,
										${IMG.SERVANT}/jobs/1528.png 2x,
										${IMG.SERVANT}/jobs/2292.png 3x`}
                    />
                    <img
                      alt="Servant: job search"
                      src={`${IMG.SERVANT}/jobs/2556.png`}
                      width="288"
                      height="206"
                      loading="lazy"
                    />
                  </picture>
                  <picture>
                    <source
                      media="(max-width: 428px)"
                      srcSet={`
										${IMG.SERVANT}/profile/382.avif,
										${IMG.SERVANT}/profile/764.avif 2x,
										${IMG.SERVANT}/profile/984.avif 3x`}
                      type="image/avif"
                    />
                    <source
                      media="(max-width: 428px)"
                      srcSet={`
										${IMG.SERVANT}/profile/382.webp,
										${IMG.SERVANT}/profile/764.webp 2x,
										${IMG.SERVANT}/profile/984.webp 3x`}
                      type="image/webp"
                    />
                    <source
                      media="(max-width: 428px)"
                      srcSet={`
										${IMG.SERVANT}/profile/382.png,
										${IMG.SERVANT}/profile/764.png 2x,
										${IMG.SERVANT}/profile/984.png 3x`}
                    />
                    <source
                      media="(min-width: 429px) and (max-width: 767px)"
                      srcSet={`
										${IMG.SERVANT}/profile/594.avif,
										${IMG.SERVANT}/profile/1188.avif 2x,
										${IMG.SERVANT}/profile/1782.avif 3x`}
                      type="image/avif"
                    />
                    <source
                      media="(min-width: 429px) and (max-width: 767px)"
                      srcSet={`
										${IMG.SERVANT}/profile/594.webp,
										${IMG.SERVANT}/profile/1188.webp 2x,
										${IMG.SERVANT}/profile/1782.webp 3x`}
                      type="image/webp"
                    />
                    <source
                      media="(min-width: 429px) and (max-width: 767px)"
                      srcSet={`
										${IMG.SERVANT}/profile/594.png,
										${IMG.SERVANT}/profile/1188.png 2x,
										${IMG.SERVANT}/profile/1782.png 3x`}
                    />
                    <source
                      media="(min-width: 768px)"
                      srcSet={`
										${IMG.SERVANT}/profile/764.avif,
										${IMG.SERVANT}/profile/1528.avif 2x,
										${IMG.SERVANT}/profile/2292.avif 3x`}
                      type="image/avif"
                    />
                    <source
                      media="(min-width: 768px)"
                      srcSet={`
										${IMG.SERVANT}/profile/764.webp,
										${IMG.SERVANT}/profile/1528.webp 2x,
										${IMG.SERVANT}/profile/2292.webp 3x`}
                      type="image/webp"
                    />
                    <source
                      media="(min-width: 768px)"
                      srcSet={`
										${IMG.SERVANT}/profile/764.png,
										${IMG.SERVANT}/profile/1528.png 2x,
										${IMG.SERVANT}/profile/2292.png 3x`}
                    />
                    <img
                      alt="Servant: company profile"
                      src={`${IMG.SERVANT}/profile/2556.png`}
                      width="288"
                      height="206"
                      loading="lazy"
                    />
                  </picture>
                </section>
                <section
                  className={cx(
                    styles.servantBlock__submit,
                    styles.section,
                    "common__article__section"
                  )}
                >
                  <div>
                    <p>
                      When creating a new job post, employers define skill
                      category, location, salary and personal contact data. An
                      employee can review the employer profile before applying
                      for a specific job and see the employer’s rating and
                      feedback from other users. The users can also like a
                      vacancy, share it on social media and see how many
                      candidates have applied for the job.
                    </p>
                  </div>
                  <div>
                    <picture className={styles.servantBlock__img__submit}>
                      <source
                        media="(max-width: 428px)"
                        srcSet={`
											${IMG.SERVANT}/submit/382.avif,
											${IMG.SERVANT}/submit/764.avif 2x,
											${IMG.SERVANT}/submit/984.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        media="(max-width: 428px)"
                        srcSet={`
											${IMG.SERVANT}/submit/382.webp,
											${IMG.SERVANT}/submit/764.webp 2x,
											${IMG.SERVANT}/submit/984.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        media="(max-width: 428px)"
                        srcSet={`
											${IMG.SERVANT}/submit/382.png,
											${IMG.SERVANT}/submit/764.png 2x,
											${IMG.SERVANT}/submit/984.png 3x`}
                      />
                      <source
                        media="(min-width: 429px)"
                        srcSet={`
											${IMG.SERVANT}/submit/594.avif,
											${IMG.SERVANT}/submit/1188.avif 2x,
											${IMG.SERVANT}/submit/1782.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        media="(min-width: 429px)"
                        srcSet={`
											${IMG.SERVANT}/submit/594.webp,
											${IMG.SERVANT}/submit/1188.webp 2x,
											${IMG.SERVANT}/submit/1782.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        media="(min-width: 429px)"
                        srcSet={`
											${IMG.SERVANT}/submit/594.png,
											${IMG.SERVANT}/submit/1188.png 2x,
											${IMG.SERVANT}/submit/1782.png 3x`}
                      />
                      <img
                        alt="Servant: submit an ad"
                        src={`${IMG.SERVANT}/submit/1782.png`}
                        width="288"
                        height="202"
                        loading="lazy"
                      />
                    </picture>
                    <picture className={styles.servantBlock__img__lng}>
                      <source
                        srcSet={`
											${IMG.SERVANT}/language/382.avif,
											${IMG.SERVANT}/language/764.avif 2x,
											${IMG.SERVANT}/language/984.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`
											${IMG.SERVANT}/language/382.webp,
											${IMG.SERVANT}/language/764.webp 2x,
											${IMG.SERVANT}/language/984.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        srcSet={`
											${IMG.SERVANT}/language/382.png,
											${IMG.SERVANT}/language/764.png 2x,
											${IMG.SERVANT}/language/984.png 3x`}
                      />
                      <img
                        alt="Servant: switch language"
                        src={`${IMG.SERVANT}/language/984.png`}
                        width="280"
                        height="243"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                </section>
                <section
                  className={cx(
                    styles.servantBlock__search,
                    styles.section,
                    "common__article__section"
                  )}
                >
                  <div>
                    <p>
                      Servant has an easy-to-use and intuitive search page for
                      employees. Employees can filter the required fields to
                      display results according to their expectations. An
                      employee can review the employer profile before applying
                      for a specific job and see the employer’s rating and
                      feedback from other users.
                    </p>
                  </div>
                  <div>
                    <picture className={styles.servantBlock__img__skills}>
                      <source
                        srcSet={`
											${IMG.SERVANT}/skills/382.avif,
											${IMG.SERVANT}/skills/764.avif 2x,
											${IMG.SERVANT}/skills/984.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`
											${IMG.SERVANT}/skills/382.webp,
											${IMG.SERVANT}/skills/764.webp 2x,
											${IMG.SERVANT}/skills/984.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        srcSet={`
											${IMG.SERVANT}/skills/382.png,
											${IMG.SERVANT}/skills/764.png 2x,
											${IMG.SERVANT}/skills/984.png 3x`}
                      />
                      <img
                        alt="Servant: useful habits"
                        src={`${IMG.SERVANT}/skills/984.png`}
                        width="288"
                        height="206"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                </section>
                <section
                  className={cx(
                    styles.servantBlock__insights,
                    "common__article__section upTo2cols"
                  )}
                >
                  <h2>Developer’s insight</h2>
                  <section>
                    <figure>
                      <picture>
                        <source
                          srcSet={`${IMG.SERVANT}/team/sofia-nabyvanets.avif`}
                          type="image/avif"
                        />
                        <source
                          srcSet={`${IMG.SERVANT}/team/sofia-nabyvanets.webp`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`${IMG.SERVANT}/team/sofia-nabyvanets.jpg`}
                        />
                        <img
                          alt="Sofia Nabyvanets"
                          title="Sofia Nabyvanets"
                          src={`${IMG.SERVANT}/team/sofia-nabyvanets.jpg`}
                          width="80"
                          height="80"
                          loading="lazy"
                        />
                      </picture>
                      <figcaption>
                        <strong>Sofia Nabyvanets</strong>
                        <small>Back-end developer</small>
                      </figcaption>
                    </figure>
                    <blockquote>
                      <p>
                        It was an exciting experience for me to work on the
                        implementation of SEO on this project. We have a
                        single-page application, so content builds dynamically
                        on the browser side. First, it was necessary to
                        determine whether the request was coming from a client
                        or an Internet bot. Depending on the result, we
                        determined which page returned — a static one, generated
                        on the server side or a dynamic one, developed on the
                        front-end. Second, we worked on the sitemap’s dynamic
                        generation based on users’ vacancies. It allowed us to
                        index all the jobs created in our service and to
                        accelerate the job search directly in the network.
                      </p>
                      <p>
                        Also, we have provided a smart search of vacancies and
                        users through filtering, sorting and searching by
                        keywords. To manage the data, we connected Rails Admin
                        and implemented integration with MailChimp. MailChimp
                        collects user contacts and groups users by defined
                        criteria (age, categories, etc.). This enabled marketing
                        information to be sent to the right group of users
                        automatically.
                      </p>
                    </blockquote>
                  </section>
                  <section>
                    <figure>
                      <picture>
                        <source
                          srcSet={`${IMG.SERVANT}/team/oleksandr-alimov.avif`}
                          type="image/avif"
                        />
                        <source
                          srcSet={`${IMG.SERVANT}/team/oleksandr-alimov.webp`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`${IMG.SERVANT}/team/oleksandr-alimov.jpg`}
                        />
                        <img
                          alt="Oleksandr Alimov"
                          title="Oleksandr Alimov"
                          src={`${IMG.SERVANT}/team/oleksandr-alimov.jpg`}
                          width="80"
                          height="80"
                          loading="lazy"
                        />
                      </picture>
                      <figcaption>
                        <strong>Oleksandr Alimov</strong>
                        <small>Front-end developer</small>
                      </figcaption>
                    </figure>
                    <blockquote>
                      <p>
                        The architecture of the Servant project was built as a
                        single-page application which allows the information
                        (such as finding jobs or performers) to be obtained
                        while reloading only the required portion and not the
                        entire project.
                      </p>
                      <p>
                        We worked with CSS styles using Scalable and Modular
                        Architecture for CSS (SMACSS). The SMACSS module makes
                        it possible to organize CSS code modularly, thereby
                        reducing the code quantity and improving the performance
                        of the service.
                      </p>
                      <p>
                        Also, SMACSS makes SCC components reusable. This cuts
                        cost saves time and allows future components to be
                        re-used for new writing styles without losing quality.
                      </p>
                    </blockquote>
                  </section>
                </section>
              </article>
            </main>
            <FeedbackSection number={0} />
          </>
        )
      }}
    </Layout>
  )
}

export default Servant
